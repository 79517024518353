import Button from 'Atoms/Buttons/Button';
import GridItem from 'Atoms/Grids/GridItem';
import { H1 } from 'Atoms/Typography/Headings/Heading';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import KexLink from 'Kex/KexLink';
import HeroBlockModel from 'Models/Blocks/HeroBlock/HeroBlockModel.interface';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

type PropType = {
  content: HeroBlockModel;
};

function HeroBlock({
  content: {
    heading,
    bodyText,
    inEditMode,
    image,
    link,
    applyGreenFilterToImage,
  },
}: PropType) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);

  return (
    <GridItem layout={'FullWidth'}>
      <ImageContainer
        applyGreenFilterToImage={applyGreenFilterToImage}
        css={{
          backgroundImage: isMobile
            ? `url(${image.mobileSrc})`
            : `url(${image.src})`,
        }}
      >
        <ContentContainer css={{ mt: 'auto' }}>
          <InnnerWrapper>
            <H1
              css={{ mb: 4 }}
              color="secondary"
              noMargin
              {...applyEditModeAttr(inEditMode && 'Heading')}
            >
              {heading}
            </H1>
            {bodyText && (
              <Paragraph size={isMobile ? 's' : 'l'}>{bodyText}</Paragraph>
            )}
            {link && (
              <LinkWrapper>
                <StyledKexLink
                  href={link.href}
                  {...applyEditModeAttr(inEditMode && 'Link')}
                  underlined={false}
                >
                  <Button type="secondary" size="s" fullWidth={isMobile}>
                    {link.text}
                  </Button>
                </StyledKexLink>
              </LinkWrapper>
            )}
          </InnnerWrapper>
        </ContentContainer>
      </ImageContainer>
    </GridItem>
  );
}

const StyledKexLink = styled(KexLink, {
  w: '100%',
});

const ImageContainer = styled('div', {
  variants: {
    applyGreenFilterToImage: {
      true: {
        '&:before': {
          backgroundColor: '$JE68GreenPrimary',
        },
      },
      false: {
        '&:before': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  position: 'relative',
  display: 'flex',
  backgroundRepeat: 'no-repeat',
  flexDirection: 'column',
  color: '$heroTextPrimary',
  h: 98,
  backgroundSize: 'cover',
  mb: 16,
  '@mediaMinMassive': {
    h: '$heroBlockWideScreenHeight',
  },
  '&:before': {
    position: 'absolute',
    content: '""',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    mixBlendMode: 'multiply',
    opacity: 1,
  },
});

const InnnerWrapper = styled('div', {
  mt: 'auto',
  position: 'relative',
  mb: 8,
  '@mediaMinMedium': {
    maxW: '50%',
    mb: 16,
  },
  bottom: 0,
});

const LinkWrapper = styled('div', {
  mt: 8,
});

export default HeroBlock;
